import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import ClassNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import Grid from '@material-ui/core/Grid';

import AssetHelper, {ImageVariants} from 'AssetHelper';
import DateIcon from 'static/home/made-by-me/ic_date.svg';
import PasswordIcon from 'static/home/made-by-me/ic_password.svg';
import PublicIcon from 'static/home/made-by-me/ic_public.svg';
import ProtectedIcon from 'static/home/made-by-me/ic_protected.svg';
import OwnerIcon from 'static/home/made-by-me/ic_owner.svg';
import ViewsIcon from 'static/home/made-by-me/ic_views.svg';

const styles = theme => ({
  root: {
    marginTop: 50,
    [theme.breakpoints.down('md')] : {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')] : {
      flexWrap: 'nowrap',
    }
  },
  presWithCat: {
    marginTop: 0,
    marginBottom: 50,
  },
  listIcon: {
    width: 32,
  },
  iconButton: {
    marginBottom: -13,
  },
  menu: {
    backgroundColor: theme.palette.lightGray
  },
  menuItem: {
    width: 342,
    color: theme.palette.mediumBlue,
    fontSize: 14,
    backgroundColor: theme.palette.lightGray
  },
  selectedMenuItem: {
    backgroundColor: '#EFEFEF !important',
    fontWeight: 600,
  },
  button: {
    width: 130,
    backgroundColor: 'white',
    border: '1px solid #006783',
    borderRadius: 5,
    color: theme.palette.mediumBlue,
    fontSize: 13,
    padding: 5,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')] : {
      marginBottom: 5,
      width: 110,
    }
  },
  buttonYellow: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  editButton: {
    backgroundColor: 'rgba(0, 0, 0 ,0)',
    border: '1px solid #FFF100',
    borderRadius: 5,
    color: theme.palette.secondary.main,
    fontSize: 13,
    display: 'block',
    marginTop: 20,
    padding: '5px 20px',
    cursor: 'pointer',
    zIndex: 999999,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    [theme.breakpoints.down('md')] : {
      display: 'none !important',
    },
  },
  overlay: {
    flexDirection: 'column',
  },
  buttonsRow: {
    width: 607,
    minWidth: 607,
    [theme.breakpoints.down('md')] : {
      width: '100%',
    },
    [theme.breakpoints.down('sm')] : {
      minWidth: 'auto',
      width: '90vw',
      flexWrap: 'wrap',
    }
  },
  buttonsRowCat: {
    width: 482,
    minWidth: 482,
    [theme.breakpoints.down('md')] : {
      width: '100%',
    },
    [theme.breakpoints.down('sm')] : {
      minWidth: 'auto',
      width: '90vw',
    }
  },
  presentationData: {
    marginRight: 30,
    marginLeft: 10,
    fontSize: 13,
    [theme.breakpoints.down('sm')] : {
      marginRight: 8,
      marginLeft: 5,
    }
  },
  presentationDataIcon: {
    width: 32,
    minWidth: 32,
  },
  passwordIconDisabled: {
    filter: 'invert(62%) sepia(13%) saturate(13%) hue-rotate(352deg) brightness(96%) contrast(87%)',
  },
  presentationDataRow: {
    marginBottom: 10,
    marginTop: 6,
    [theme.breakpoints.down('md')] : {
      marginTop: 30,
    },
  },
  presentationDataContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')] : {
      maxWidth: 110,
      overflow: 'hidden',
    },
  },
  presentationDetails: {
    flex: 1,
    maxWidth: 607,
    marginLeft: 16,
    height: 240,
    maxHeight: 240,
    [theme.breakpoints.down('md')] : {
      width: '100%',
      flexDirection: 'column',
      maxWidth: 863,
      marginLeft: 0,
      marginTop: 24,
      height: 'auto',
      maxHeight: 'none',
    },
    [theme.breakpoints.down('sm')] : {
      maxWidth: '90vw',
      width: '90vw',
    }
  },
  presentationDetailsCat: {
    maxWidth: 475,
    [theme.breakpoints.down('md')] : {
      maxWidth: 863,
    },
    [theme.breakpoints.down('sm')] : {
      maxWidth: '90vw',
    }
  },
  playImg: {
    width: 80,
    height: 80,
  },
  link: {
    width: 592,
    height: 240,
    [theme.breakpoints.down('md')] : {
      width: 863,
    },
    [theme.breakpoints.down('sm')] : {
      width: '90vw',
    },
  },
  linkCat: {
    width: 420,
    [theme.breakpoints.down('md')] : {
      width: 863,
    },
    [theme.breakpoints.down('sm')] : {
      width: '90vw',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 27,
    color: '#333333',
    marginBottom: 10,
    maxHeight: 62,
    overflow: 'hidden',
  },
  descriptionText: {
    height: 80,
    overflow: 'hidden',
    fontSize: 13,
    lineHeight: 2,
    letterSpacing: '0.4sp',
    color: '#333333',
    maxWidth: '80%',
    [theme.breakpoints.down('md')] : {
      height: 'auto',
    },
    [theme.breakpoints.down('sm')] : {
      maxWidth: '100%',
    }
  },
  description: {
    height: 166,
    maxHeight: 166,
    overflow: 'hidden',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')] : {
      height: 'auto',
      maxHeight: 'none',
    },
  },
  presentationBackground: {
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
});

const smBreakpoint = 960;
const sizes = { small: 13, medium: 30 };

function formatDescription(html) {
  let htmlText = html && html.replace(/<\/?[^>]+(>|$)/g, '').replace('&nbsp;', '');
  if (htmlText && htmlText.length > 229) {
    htmlText = `${htmlText.slice(0, 230)}...`;
  }
  return htmlText;
}

function formatDisplayName(text, window) {
  if (text && text.length > sizes[window]) {
    return `${text.slice(0, sizes[window] - 1)}...`;
  } else {
    return text;
  }
}

class Presentations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      window: window.innerWidth < smBreakpoint ? 'small' : 'medium',
    };
    this.onResizeUpdate = this.onResizeUpdate.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.onResizeUpdate.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeUpdate.bind(this));
  }
  onResizeUpdate() {
    this.setState({  window: window.innerWidth < smBreakpoint ? 'small' : 'medium' });
  }
  render() {
    const { classes, onEmailModalOpen, onEmbedModalOpen, onViewingModalOpen, categoriesEnabled, t } = this.props;
    const playImgSrc = 'static/home/made-by-me/ic_play-svg.png';

    return (
      this.props.items.map((item) => {
        const hash = item.thumbnail && item.thumbnail.hash;
        const imgUrl = hash ? AssetHelper.imagePath(hash, ImageVariants.WIDE) : '';
        const presentationLink = '/p/' + item.hash + '-' + item.alias;
        const presentationEditLink = `/panel/#/projects/${item.id}/edit`;
        return (
          <React.Fragment>
            <Grid container direction="row" className={ClassNames(classes.root, categoriesEnabled && classes.presWithCat)}>
              <Grid item>
                <Link to={presentationLink} className={ClassNames('presentations__link', classes.link, categoriesEnabled && classes.linkCat)}>
                  <div className={ClassNames(classes.presentationBackground, 'presentations__background')} style={{backgroundImage: `url("${imgUrl}")`}}>
                    <div className={ClassNames('presentations__overlay', classes.overlay)}>
                      <img src={playImgSrc} className={classes.playImg} />
                      <button
                        className={classes.editButton}
                        onClick={() => {
                          window.open(presentationEditLink, '_self');
                        }}
                      >
                        {t('madeByMe.presentation.edit')}
                      </button>
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item className={ClassNames(classes.presentationDetails, categoriesEnabled && classes.presentationDetailsCat)}>
                <Grid container direction="column" className={classes.description}>
                  <Grid item>
                    <div className={classes.title}>
                      {item.name}
                    </div>
                  </Grid>
                  <Grid item className={classes.descriptionText}>
                    <span>
                      {formatDescription(item.description)}
                    </span>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.presentationDataRow}>
                  <Grid item className={classes.presentationDataContainer}>
                    <DateIcon className={classes.presentationDataIcon} />
                    <span className={classes.presentationData}>{format(item.publicationDate, 'dd-MM-yyyy')}</span>
                  </Grid>
                  <Grid item className={classes.presentationDataContainer}>
                    <OwnerIcon className={classes.presentationDataIcon} />
                    <span className={classes.presentationData}>{formatDisplayName(item.author.displayName, this.state.window)}</span>
                  </Grid>
                  <Grid item className={classes.presentationDataContainer}>
                    {item.access === 'PROTECTED' ? (
                      <React.Fragment>
                        {item.hasPassword ? (
                          <React.Fragment>
                            <PasswordIcon className={classes.presentationDataIcon} />
                            <span className={classes.presentationData}>{t('madeByMe.presentation.password')}</span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <ProtectedIcon className={classes.presentationDataIcon} />
                            <span className={classes.presentationData}>{t('madeByMe.presentation.protected')}</span>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <PublicIcon className={classes.presentationDataIcon} />
                        <span className={classes.presentationData}>{t('madeByMe.presentation.public')}</span>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid item className={classes.presentationDataContainer}>
                    <ViewsIcon className={classes.presentationDataIcon} />
                    <span className={classes.presentationData}>{item.viewCount}</span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="stretch"
                  className={ClassNames(classes.buttonsRow, categoriesEnabled && classes.buttonsRowCat)}
                >
                  <Grid item>
                    <button className={classes.button} onClick={() => onEmailModalOpen(item)}>{t('madeByMe.presentation.sendEmail')}</button>
                  </Grid>
                  <Grid item>
                    <button className={classes.button} onClick={() => onEmbedModalOpen(item)}>{t('madeByMe.presentation.share')}</button>
                  </Grid>
                  <Grid item>
                    <button className={ClassNames(classes.button, classes.buttonYellow)} onClick={() => onViewingModalOpen(item.id)}>
                      {t('madeByMe.presentation.coviewing')}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })
    );
  }
}

Presentations.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired
  ).isRequired,
  onEmbedModalOpen: PropTypes.func.isRequired,
  onEmailModalOpen: PropTypes.func.isRequired,
  onViewingModalOpen: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  translate('translations')
)(Presentations);
