import React, {Component} from "react";
import { translate } from "react-i18next";
import CtaBlock from "../../components/cta-block/index";
import './index.scss';
import { NavBarStyles } from 'components/navBar';

class NotFound extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount() {
    this.props.navBar.setStyle(NavBarStyles.dark);
  }
  componentWillUnmount() {
    this.props.navBar.setStyle();
  }
  render(){
    const { t } = this.props;

    return (<div className="not-found-page">
      <h1>{t("notfound.title")}</h1>

      <CtaBlock title={t("ctablock.header")}>
        <p className="anim">
          {t("ctablock.subheader")}
        </p>
        <a href="#" className="btn anim " data-reveal-id="signup">
          {t("ctablock.trybtn")}
        </a>
      </CtaBlock>
    </div>);
  }
}

export default translate("translations")(NotFound);
