import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImgEmpty from 'static/home/made-by-me/img_madebyme_empty_state.svg';

const styles = theme => ({
  img: {
    maxWidth: 800,
    marginTop: 40,
    marginBottom: 20
  },
  button: {
    border: '1px solid #006783',
    borderRadius: 5,
    color: theme.palette.mediumBlue,
    fontSize: 13,
    marginTop: 32,
    padding: '5px 49px 5px 49px',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
});

class EmptyState extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t, classes } = this.props;

    return (
      <Grid direction="column" justify="center" alignItems="center" style={{ textAlign: 'center' }}>
        <ImgEmpty className={classes.img} />
        <Typography><b>{t('madeByMe.emptyState.empty')}</b></Typography>
        <button className={classes.button} onClick={() => window.open('/panel/#/projects')}>
          {t('madeByMe.emptyState.create')}
        </button>
      </Grid>
    );
  }
}

EmptyState.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default compose(
  withStyles(styles),
  translate('translations')
)(EmptyState);
