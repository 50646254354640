import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import ClassNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import Modal from 'components/modal/index.jsx';
import FacebookIcon from 'static/home/made-by-me/ic_facebook.svg';

import shareOnFacebook from 'shared/shareOnFacebook';

const styles = theme => ({
  root: {
    color: theme.palette.darkGray,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '& .modal__body': {
      padding: 24,
      width: 600,
      [theme.breakpoints.down('md')] : {
        width: '100%',
      },
    },
    '& .modal__window': {
      [theme.breakpoints.down('md')] : {
        maxHeight: 'none',
        width: '100%',
        height: '100%',
      },
    },
    '& .modal__close': {
      width: '25px !important',
      height: 20,
    },
    '& .jss221': {
      minHeight: 'none',
    }
  },
  title: {
    fontSize: 27,
    lineHeight: 1,
    fontWeight: 600,
    textAlign: 'left',
    maxWidth: '90%',
  },
  mediaRow: {
    backgroundColor: theme.palette.accentBlue,
    height: 40,
    width: 210,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 7,
    marginBottom: 16,
    marginTop: 24,
    color: 'white',
    cursor: 'pointer',
  },
  mediaIcon: {
    height: 32,
    fill: 'none',
    cursor: 'pointer',
    marginRight: 8,
  },
  hr: {
    width: '100%',
    height: 2,
    backgroundColor: 'gray',
    marginBottom: 24,
  },
  info1: {
    textAlign: 'left',
  },
  firstLinkContainer: {
    [theme.breakpoints.down('sm')] : {
      justifyContent: 'flex-end',
    },
  },
  embedLink: {
    padding: 10,
    width: 376,
    border: '1px solid #006783',
    borderRadius: 7,
    textAlign: 'left',
    margin: '9px 0 9px 0',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'brandon, sans-serif',
    lineHeight: '1.46429em',
  },
  linkFirst: {
    [theme.breakpoints.down('md')] : {
      flex: '2 1 auto',
    },
    [theme.breakpoints.down('xs')] : {
    },
  },
  firstCopy: {
    marginLeft: 15,
  },
  copyButton: {
    color: theme.palette.mediumBlue,
    fontSize: 13,
    padding: 5,
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'none',
    height: 28,
    width: 160,
    border: 'none',
    borderRadius: 5,
    minHeight: 'none !important',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,

    },
    '& .jss221': {
      minHeight: 'none',
    }
  },
  info2: {
    textAlign: 'left',
    marginTop: 5,
  },
  html: {
    width: '100%',
    height: 144,
  },
  sizes: {
    width: 70,
    marginLeft: 10,
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
  },
  bottomContainer: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')] : {
      flexWrap: 'wrap',
    },
  },
  sizesContainer: {
    flexWrap: 'nowrap',
  },
  checkboxContainer: {
    minWidth: 223,
    width: 'auto',
    '& .pure-material-checkbox > input': {
      content: "",
      display: 'inline-block',
      boxSizing: 'border-box',
      margin: '3px 11px 3px 1px',
      border: 'solid 2px',
      borderColor: 'red',
      borderRadius: '2px',
      width: '18px',
      height: '18px',
      verticalAlign: 'top',
      transition: 'border-color 0.2s, background-color 0.2s',
    },
    [theme.breakpoints.down('sm')] : {
      marginTop: 5,
    },
  },
  checkbox: {
    padding: 0,
    marginRight: 5,
  },
  secondButton: {
    marginTop: 10,
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  textArea: {
    border: 'none',
    resize: 'none',
    height: '100%',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'brandon, sans-serif',
    lineHeight: '1.46429em',
  },
  shareFb: {
    color: 'white',
  },
});

class EmbedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSnackbarOpen: false,
      width: 800,
      height: 600,
      autoplay: '',
      copiedMsg: this.props.t('common.copied')
    };
    this.handleCopy = this.handleCopy.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleToggleAutoplay = this.handleToggleAutoplay.bind(this);
  }
  handleToggleAutoplay() {
    if (this.state.autoplay) {
      this.setState({ autoplay: '' });
    } else {
      this.setState({ autoplay: '?autoplay' });
    }
  }
  handleCopy(el) {
    const { copiedMsg } = this.state;
    el.select();
    document.execCommand('copy');
    this.props.onSnackbarOpen(copiedMsg);
  }
  handleClose() {
    const { onClose } = this.props;
    onClose();
    this.setState({ width: 800, height: 600 });
  }
  render() {
    const { classes, presentation, open, t } = this.props;
    const presentatonLink = `${window.location.origin}/embed/${presentation.hash}`;
    const embedLink = `${presentatonLink}${this.state.autoplay}`;
    const html = `<iframe witdh="${this.state.width}px" height="${this.state.height}px" scrolling="no" src="${embedLink}" frameborder="0" allowfullscreen></iframe>`;
    return (
      <Modal
        open={open}
        onClose={this.handleClose}
        className={classes.root}
      >
        <Grid container direction="column">
          <Typography className={classes.title}>{t('madeByMe.embedModal.sharePresentation')} {presentation.name}</Typography>
          <Grid container direction="row" alignItems="center" className={classes.mediaRow} onClick={() => shareOnFacebook(presentation.hash)}>
            <FacebookIcon className={classes.mediaIcon} />
            <Typography className={classes.shareFb}>{t('madeByMe.emailModal.shareOnFacebook')}</Typography>
          </Grid>
          <div className={classes.hr} />
          <Typography className={classes.info1}>{t('madeByMe.embedModal.text1')}</Typography>
          <Grid container direction="row" justify="space-between" alignItems="center" className={classes.firstLinkContainer}>
            <input
              className={ClassNames(classes.embedLink, classes.linkFirst)}
              ref={(link) => this.link = link}
              value={presentatonLink}
            />
            <button className={ClassNames(classes.copyButton, classes.firstCopy)} onClick={() => this.handleCopy(this.link)}>
              {t('madeByMe.embedModal.copyLink')}
            </button>
          </Grid>
          <Typography className={classes.info2}>{t('madeByMe.embedModal.text2')}</Typography>
          <Grid className={ClassNames(classes.embedLink, classes.html)}>
            <textarea
              className={classes.textArea}
              ref={(textarea) => this.textArea = textarea}
              value={html}
            />
          </Grid>
          <Grid container direction="row" alignContent="center" alignItems="center" className={classes.bottomContainer}>
            <Grid container direction="row" className={classes.sizesContainer}>
              <Grid container direction="row" alignContent="center" alignItems="center" className={classes.noWrap}>
                <Typography>{t('madeByMe.embedModal.width')}</Typography>
                <input
                  type="number"
                  min="10"
                  className={ClassNames(classes.embedLink, classes.sizes)}
                  value={this.state.width}
                  onChange={(e) => {
                    if (e.target.value > -1 && /^\d+$/.test(e.target.value)) {
                      this.setState({ width: parseInt(e.target.value, 10) });
                    } else {
                      this.setState({ width: 800 });
                    }
                  }}
                />
              </Grid>
              <Grid container direction="row" alignContent="center" alignItems="center" className={classes.noWrap}>
                <Typography>{t('madeByMe.embedModal.height')}</Typography>
                <input
                  type="number"
                  min="0"
                  className={ClassNames(classes.embedLink, classes.sizes)}
                  value={this.state.height}
                  onChange={(e) => {
                    if (e.target.value > -1 && /^\d+$/.test(e.target.value)) {
                      this.setState({ height: parseInt(e.target.value, 10) });
                    } else {
                      this.setState({ height: 600 });
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" alignContent="center" alignItems="center" className={classes.checkboxContainer}>
              <Checkbox
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
                className={classes.checkbox}
                onChange={this.handleToggleAutoplay}
                color="primary" />
              <Typography>{t('madeByMe.embedModal.noStartScreen')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="flex-end">
            <button className={ClassNames(classes.copyButton, classes.secondButton)} onClick={() => this.handleCopy(this.textArea)}>
              {t('madeByMe.embedModal.copyLink')}
            </button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

EmbedModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  presentation: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onSnackbarOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  translate('translations')
)(EmbedModal);
