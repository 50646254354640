import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import Classnames from "classnames";
import withStyles from '@material-ui/core/styles/withStyles';

import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import PropTypes from "prop-types";

const menuOptions = [
  { value: 'publicationDateDesc' },
  { value: 'publicationDateAsc' },
  { value: 'nameAsc' },
  { value: 'nameDesc' },
  { value: 'viewCountAsc' },
  { value: 'viewCountDesc' }
];

const styles = theme => ({
  title: {
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
  },
  icon: {
    marginBottom: -7,
  },
  menuItem: {
    cursor: 'pointer',
    width: 168,
    color: theme.palette.mediumBlue,
    fontSize: 14,
    backgroundColor: theme.palette.lightGray,
  },
  selectedMenuItem: {
    backgroundColor: '#EFEFEF !important',
    fontWeight: 600,
  },
  menu: {
    width: 200,
    position: 'fixed',
    top: 175,
    right: 90,
    fontSize: 50,
    zIndex: 1,
    backgroundColor: theme.palette.lightGray,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')] : {
      top: 200,
      right: 15,
    },
  }
});

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleChangeSort(newSort) {
    if (this.props.onSortChange) {
      this.props.onSortChange(newSort);
      this.handleClose();
    }
  }
  handleOpen() {
    this.setState({ open: true });
  }
  handleClose() {
    this.setState({ open: false });
  }
  render() {
    const { t, sort, classes } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Typography onClick={() => open ? this.handleClose() : this.handleOpen()} className={classes.title}>
          {t(`madeByMe.page.sortBy`)} <b>{t(`madeByMe.filter.${sort}`)}</b>
          {open ? <ArrowDropDownIcon className={classes.icon} /> : <ArrowDropUpIcon className={classes.icon} />}
        </Typography>
        {open && <div className={classes.menu}>
          {menuOptions.map((item) => {
            const selected = sort === item.value;
            return (
              <MenuItem
                selected={selected}
                className={Classnames(classes.menuItem, selected && classes.selectedMenuItem)}
                onClick={() => {
                  this.handleChangeSort(item.value);
                }}
              >
                {t(`madeByMe.filter.${item.value}`)}
              </MenuItem>
            );
          })}
        </div>}
      </React.Fragment>
    );
  }
}

Filter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.string,
};

Filter.defaultProps = {
  sort: 'publicationDateDesc'
};

export default compose(
  withStyles(styles),
  translate('translations')
)(Filter);
